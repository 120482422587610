<label [ngClass]="{'filled': value && value.length > 0}">
  @if (label()) {
    <span>{{ label() }}</span>
  }
  <div>
  <input [type]="isPassword() ? 'password' : 'text'" [(ngModel)]="value" [placeholder]="placeholder()" (ngModelChange)="valueChange()" [ngClass]="{'visible' : isVisible, 'withToggle' : isPassword}" [autocomplete]="isVisible ? 'one-time-code' : 'current-password'">
  <!-- <span [ngClass]="{'placeholder': !value}" data-cursor="|">{{ (value && (!isPassword || (isPassword && isVisible))) ? value : (value ? '*'.repeat(value.length) : placeholder) }}</span> -->
  @if (isPassword()) {
     @if (isVisible && value) {
      <span>{{value}}</span>
    }
    <button type="button" (mousedown)="togglePasswordVisibility()" (dragstart)="preventDrag($event)">
    <img [src]="isVisible ? 'assets/icons/visible.svg' : 'assets/icons/invisible.svg'" alt=""></button>
  }
</div>
</label>
